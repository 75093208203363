<script>
  import request from "axios";
  import NickelLogo from "../common/NickelLogo.svelte";
  import Step1 from "./Step1.svelte";
  import Step2 from "./Step2.svelte";
  import Step3 from "./Step3.svelte";
  import Step4 from "./Step4.svelte";
  import Progress from "./Progress.svelte";
  const production = !process.env.ROLLUP_WATCH;
  const apiPrefix = production ? "" : "dev.";

  export let setCompleted = () => null;

  let clientWidth;

  const titles = {
    1: "How can Nickel help your athletic department the most?",
    2: "What data systems do you currently use?",
    3: "Let's take a look at what you've filled out so far.",
    4: "It looks like Nickel may be a good fit for you!"
  };

  const subtitles = {
    1: "Select all that apply.",
    2: "Nearly there! Just a few more questions.",
    3: "If all looks good, hit next to submit.",
    4: "Let us know how you would like us to reach out."
  };

  let step = 1;

  let reasonSelections = [];
  const toggleReasonSelection = selection => () => {
    if (reasonSelections.includes(selection)) {
      reasonSelections = reasonSelections.filter(s => s !== selection);
    } else {
      reasonSelections = reasonSelections.concat(selection);
    }
  };

  let ticketingSelection = "";
  const setTicketingSelection = selection => {
    ticketingSelection = selection;
  };

  let CRMSelections = [];
  const toggleCRMSelection = selection => () => {
    if (CRMSelections.includes(selection)) {
      CRMSelections = CRMSelections.filter(s => s !== selection);
    } else {
      CRMSelections = CRMSelections.concat(selection);
    }
  };

  let dataWarehouseSelection = "";
  const setDataWarehouseSelection = selection => {
    dataWarehouseSelection = selection;
  };

  let firstName = "";
  let setFirstName = e => (firstName = e.target.value);

  let lastName = "";
  let setLastName = e => (lastName = e.target.value);

  let email = "";
  const setEmail = e => (email = e.target.value);

  let phone = "";
  const setPhone = e => (phone = e.target.value);

  let completed = false;

  const previousStep = () => {
    step = (step - 1) % 5 || 1;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };
  const nextStep = () => {
    if (step < 4) step += 1;
    else {
      request
        .post(`https://${apiPrefix}api.website.nickeldxm.com/v1/walkthrough`, {
          reasonSelections,
          ticketingSelection,
          CRMSelections,
          dataWarehouseSelection,
          firstName,
          lastName,
          email,
          phone
        })
        .then(setCompleted)
        .catch(console.warn);
    }
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };

  $: title = titles[step];
  $: subtitle = subtitles[step];

  $: step1Valid = reasonSelections.length > 0;
  $: step2Valid =
    ticketingSelection && CRMSelections.length > 0 && dataWarehouseSelection;
  $: step3Valid = true;
  $: step4Valid =
    firstName &&
    firstName.length &&
    lastName &&
    lastName.length &&
    /^[^\s@]+@[^\s@]+\.[^\s@]*edu$/.test(email);
  $: nextEnabled =
    (step === 1 && step1Valid) ||
    (step === 2 && step2Valid) ||
    (step === 3 && step3Valid) ||
    (step === 4 && step4Valid);

  $: actionButtonClasses = `action button${step === 1 ? " full" : ""}${
    nextEnabled ? "" : " disabled"
  }`;
</script>

<style>
  .wrapper {
    position: relative;
    min-height: 100vh;
    width: 100vw;
    padding: 45px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #fafafa;
  }

  .blob {
    position: absolute;
    z-index: 0;
    fill: #fff;
  }
  .blob.top-left {
    top: 0;
    left: 0;
    transform: scale(-1, 1);
  }
  .blob.bottom-right {
    bottom: 0;
    right: 0;
    transform: scale(-1, -1);
  }

  .header {
    position: relative;
    z-index: 1;
    flex-shrink: 0;
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    flex-shrink: 0;
    width: 382px;
  }

  .title {
    margin-top: 45px;
    margin-bottom: 8px;
    font-size: 26px;
    line-height: 34px;
    letter-spacing: -0.8px;
    text-align: center;
  }

  .fine-print {
    width: 100%;
    margin: 0px;
    font-size: 14px;
    line-height: 22px;
    opacity: 0.3;
    text-align: center;
  }

  .step-buttons {
    position: relative;
    z-index: 1;
    flex-shrink: 0;
    height: 40px;
    width: 382px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .button {
    position: relative;
    z-index: 1;
    flex-shrink: 0;
    height: 40px;
    padding: 0;
    border-radius: 5px;
    border: none;
    outline: none;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    font-weight: 700;
    cursor: pointer;
    vertical-align: middle;
  }
  .button:active {
    opacity: 0.9;
  }

  .action.button {
    width: 191px;
    color: #fff;
    background-color: #2487ff;
  }
  .action.button.full {
    width: 382px;
  }
  .action.button.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  .back.button {
    padding: 12px;
    background-color: transparent;
    border: none;
    font-weight: 800;
  }

  /* MOBILE LAYOUT */
  @media only screen and (max-width: 882px) {
    .content {
      width: calc(100% - 20px);
    }

    .step-buttons {
      width: calc(100vw - 40px);
    }
    .action.button {
      width: 168px;
    }
    .action.button.full {
      width: 100%;
    }
  }
</style>

<div class="wrapper" bind:clientWidth>
  {#if clientWidth > 882}
    <svg
      class="blob top-left"
      width="618px"
      height="545px"
      viewBox="0 0 618 545">
      <path
        d="M68,0 L618,0 C713.265555,171.647923 713.265555,314.012506
        618,427.09375 C475.101668,596.715616 203.248367,569.674297 68,427.09375
        C-22.1655782,332.040052 -22.1655782,189.675468 68,0 Z" />
    </svg>
    <svg
      class="blob bottom-right"
      width="437px"
      height="345px"
      viewBox="0 0 437 345">
      <path
        d="M0,0.7109375 L370,0.7109375 C459.166667,164.022135
        459.166667,267.785156 370,312 C280.833333,356.214844 157.5,356.214844
        0,312 L0,0.7109375 Z"
        id="back" />
    </svg>
  {/if}
  <a class="header" href="/">
    <NickelLogo />
  </a>
  <div class="content">
    <h3 class="title">{title}</h3>
    <p class="fine-print">{subtitle}</p>
    {#if step === 1}
      <Step1 {reasonSelections} {toggleReasonSelection} />
    {:else if step === 2}
      <Step2
        {ticketingSelection}
        {setTicketingSelection}
        {CRMSelections}
        {toggleCRMSelection}
        {dataWarehouseSelection}
        {setDataWarehouseSelection} />
    {:else if step === 3}
      <Step3
        {reasonSelections}
        {ticketingSelection}
        {CRMSelections}
        {dataWarehouseSelection} />
    {:else if step === 4}
      <Step4
        {firstName}
        {setFirstName}
        {lastName}
        {setLastName}
        {email}
        {setEmail}
        {phone}
        {setPhone} />
    {/if}
  </div>
  <Progress {step} />
  <div class="step-buttons">
    {#if step > 1}
      <button class="back button" on:click={previousStep}>&#8249; Back</button>
    {/if}
    <button class={actionButtonClasses} on:click={nextStep}>
      NEXT &#8250;
    </button>
  </div>
</div>
